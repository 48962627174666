import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@mui/material';
import { LoginRequest, isAutenticated } from '../services/login.service';

const Login = () => {
  const navigate = useNavigate();
  const [showError, setshowError] = useState(false);

  useEffect(() => {
    if (isAutenticated()) {
      navigate('/app/users');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Phillogzs Back Office</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('e-mail invalide')
                .max(255)
                .required('Email est obligatoire'),
              password: Yup.string()
                .min(3, 'Le mot de passe doit avoir au moins 3 caractères')
                .max(255)
                .required('Mot de passe est obligatoire')
            })}
            onSubmit={(values, { setSubmitting }) => {
              const { email, password } = values;
              LoginRequest({
                email,
                password
              }).then(
                (res) => {
                  const { data } = res;
                  localStorage.setItem('access_token', data.access_token);
                  navigate('/app/users', { replace: true });
                  setSubmitting(false);
                },
                (error) => {
                  if (error.response.data.statusCode === 401) {
                    setshowError(true);
                    setSubmitting(false);
                  }
                }
              );
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Login
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Connectez-vous sur la plateforme
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    variant="contained"
                    type="submit"
                  >
                    Connexion
                  </Button>
                </Box>
                {showError && (
                  <Alert severity="error">
                    Votre email ou mot de passe est incorrect
                  </Alert>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
