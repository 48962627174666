import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  CircularProgress,
  Container,
} from '@mui/material';
import { isAutenticated } from '../services/login.service';
import { getCharacterById } from '../services/character.service';

const CharacterDetailsPage = () => {
  const { characterId } = useParams();
  const navigate = useNavigate();
  const [character, setcharacter] = useState([]);
  const [isRequeting, setisRequeting] = useState(true);

  const loadCharacter = async () => {
    setisRequeting(true);

    try {
      const characterResponse = await getCharacterById(characterId);
      const { data: characterData } = characterResponse;
      setcharacter(characterData);
      setisRequeting(false);
    } catch (error) {
      setisRequeting(false);
    }
  };

  useEffect(() => {
    if (isAutenticated()) {
      loadCharacter();
    } else {
      navigate('/login');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Phillogzs - Utilisateurs</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          {isRequeting ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Box>{character.firstName}</Box>
              <Box>{character.lastName}</Box>
              <Box>{character.age}</Box>
              <Box>{character.size}</Box>
              <Box>{character.weight}</Box>
              <Box>{character.sex}</Box>
              <Box>{character.hair}</Box>
              <Box>{character.eyes}</Box>
              <Box>{character.luckyNumber}</Box>
              <Box>{character.other}</Box>
              <Box>{character.archetype.name}</Box>
              <Box>{character.element.name}</Box>
              {character.origins.map((origin) => (
                <Box key={origin.id}>{origin.name}</Box>
              ))}
            </Box>
          )}
          <Box>{JSON.stringify(character, null, '\t')}</Box>
        </Container>
      </Box>
    </>
  );
};
export default CharacterDetailsPage;
