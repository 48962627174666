import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import UsersPage from 'src/pages/Users';
import CharacterCreatorPage from './pages/CharacterCreator';
import CharacterDetailsPage from './pages/CharacterDetails';
import CharactersPage from './pages/Characters';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'users', element: <UsersPage /> },
      { path: 'characters', element: <CharactersPage /> },
      { path: 'characters/:characterId', element: <CharacterDetailsPage /> },
      { path: 'characters/creator', element: <CharacterCreatorPage /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/users" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
