import { Delete, Get, Post, Put } from './network.service';

export const getCharacters = () => {
  return Get('character');
};

export const getCharacterByPaginate = (page, size) => {
  return Get(`character?page=${page}&take=${size}`);
};
export const getCharacterById = (characterId) => {
  return Get(`character/${characterId}`);
};

export const createCharacter = (body) => {
  return Post('character', body);
};

export const updateCharacter = (characterId, body) => {
  return Put(`character/${characterId}`, body);
};

export const deleteCharacter = (characterId) => {
  return Delete(`character/${characterId}`);
};
