import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Dialog,
  Grid
} from '@mui/material';
import UserListBody from '../components/users/UserListBody';
import UserForm from '../components/users/UserForm';
import ConfirmDelete from '../components/users/ConfirmDelete';
import { isAutenticated } from '../services/login.service';
import { deleteUser, getUserByPaginate } from '../services/user.service';

const UsersPage = () => {
  const navigate = useNavigate();
  const [users, setusers] = useState([]);
  const [open, setOpen] = useState(false);
  const [openConfirmationDialog, setopenConfirmationDialog] = useState(false);
  const [SelectedUser, setSelectedUser] = useState();
  const [count, setcount] = useState(0);
  const [isRequeting, setisRequeting] = useState(false);
  const [modalStyle] = useState({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  });

  const loadUsers = async (page, limit) => {
    setisRequeting(true);

    try {
      const usersResponse = await getUserByPaginate(page, limit);

      const { data: userData } = usersResponse;
      setcount(userData.pageMax);
      setusers(userData.data);

      setisRequeting(false);
    } catch (error) {
      setisRequeting(false);
    }
  };

  useEffect(() => {
    if (isAutenticated()) {
      loadUsers(0, 10);
    } else {
      navigate('/login');
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const openCreateUser = () => {
    setSelectedUser(null);
    setOpen(true);
  };

  const closeDialog = () => {
    setSelectedUser(null);
    setOpen(false);
    setopenConfirmationDialog(false);
  };

  /**
   * Delete user
   * @param {*} userData 
   */
  const onDelete = async (userToDelete) => {
    setSelectedUser(userToDelete);
    setOpen(true);
    setopenConfirmationDialog(true);

    // await deleteUser(user.id);
    // loadUsers(0, 10);
  };

  /**
   * Edit user
   * @param {*} userData 
   */
  const onEdit = async (userToEdit) => {
    console.log(userToEdit);
    setSelectedUser(userToEdit);
    setOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Phillogzs - Utilisateurs</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ mt: 3 }}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={openCreateUser}
                  >
                    Ajouter Utilisateur
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <Card>
                <CardHeader title="Users" subheader="Liste des utilisateurs" />
              </Card>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            />
            <Dialog open={open} onClose={handleClose} style={modalStyle}>
              {openConfirmationDialog ? (
                <ConfirmDelete
                  onClose={(isDelete) => {
                    if (isDelete) {
                      deleteUser(SelectedUser.id).then(() => {
                        closeDialog();
                        loadUsers(0, 10);
                      });
                    } else {
                      closeDialog();
                    }
                  }}
                />
              ) : (
                <UserForm
                  user={SelectedUser}
                  onClose={(isRefresh) => {
                    closeDialog();
                    if (isRefresh) {
                      loadUsers(0, 10);
                    }
                  }}
                />
              )}
            </Dialog>
            <Grid item lg={12}>
              <UserListBody
                data={users}
                count={count}
                onPaginate={(page, limit) => loadUsers(page, limit)}
                isRequeting={isRequeting}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default UsersPage;
