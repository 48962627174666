import { Get } from './network.service';

export const getOrigins = (archetypeId, elementId) => {
  let url = 'origin';
  let separator = '?';
  if (archetypeId !== undefined) {
    url = `${url}?archetype=${archetypeId}`;
    separator = '&';
  }
  if (elementId !== undefined) {
    url = `${url}${separator}element=${elementId}`;
  }
  return Get(url);
};

export const getOriginByPaginate = (page, size) => {
  return Get(`origin?page=${page}&take=${size}`);
};
