import PropTypes from 'prop-types';
import {
  CircularProgress, Box, Button
} from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {
  getOrigins
} from 'src/services/origin.service';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import OriginCard from './OriginCard';

const OriginSelector = ({ character, onOriginsSelected }) => {
  const [NeutralOrigin, setNeutralOrigin] = useState([]);
  const [ArchetypeOrigin, setArchetypeOrigin] = useState([]);
  const [OtherOrigin, setOtherOrigin] = useState([]);
  const [SelectedOriginIds, setSelectedOriginIds] = useState([]);
  const [isRequeting, setisRequeting] = useState(false);
  const [forceRender, setforceRender] = useState({});

  const totalNeutralOrigin = 250;
  const totalArchetypeOrigin = 200;
  const totalOtherOrigin = 200;

  const loadOrigins = async () => {
    setisRequeting(true);

    try {
      const originsResponse = await getOrigins();
      const { data: originsData } = originsResponse;
      setNeutralOrigin(originsData.filter((or) => or.archetype === undefined && or.element === undefined));
      setArchetypeOrigin(originsData.filter((or) => or.archetype !== undefined && or.archetype.id === character.archetype));
      setOtherOrigin(originsData.filter((or) => or.element !== undefined && or.element.id === character.element));
      setisRequeting(false);
    } catch (error) {
      setisRequeting(false);
    }
  };

  const isOriginSelected = (origin) => {
    // console.log('SelectedOriginIds', SelectedOriginIds);
    return SelectedOriginIds.filter((orId) => orId === origin.id).length === 1;
  };

  const remainingNeutralOrigin = () => {
    let remaining = totalNeutralOrigin;
    NeutralOrigin.forEach((origin) => {
      if (isOriginSelected(origin)) {
        remaining -= origin.cost;
      }
    });
    return remaining;
  };

  const remainingArchetypeOrigin = () => {
    let remaining = totalArchetypeOrigin;
    ArchetypeOrigin.forEach((origin) => {
      if (isOriginSelected(origin)) {
        remaining -= origin.cost;
      }
    });
    return remaining;
  };

  const remainingOtherOrigin = () => {
    let remaining = totalOtherOrigin;
    OtherOrigin.forEach((origin) => {
      if (isOriginSelected(origin)) {
        remaining -= origin.cost;
      }
    });
    return remaining;
  };

  const remainingPureOrigin = () => {
    let remaining = 0;
    NeutralOrigin.forEach((origin) => {
      if (isOriginSelected(origin) && origin.pureOrigin > 0) {
        remaining += origin.pureOrigin;
      }
    });
    ArchetypeOrigin.forEach((origin) => {
      if (isOriginSelected(origin) && origin.pureOrigin > 0) {
        remaining += origin.pureOrigin;
      }
    });
    OtherOrigin.forEach((origin) => {
      if (isOriginSelected(origin) && origin.pureOrigin > 0) {
        remaining += origin.pureOrigin;
      }
    });
    if (remainingNeutralOrigin() < 0) {
      remaining += remainingNeutralOrigin();
    }
    if (remainingArchetypeOrigin() < 0) {
      remaining += remainingArchetypeOrigin();
    }
    if (remainingOtherOrigin() < 0) {
      remaining += remainingOtherOrigin();
    }
    return remaining;
  };

  const returnSelectedOrigins = () => {
    const selected = [];
    NeutralOrigin.forEach((origin) => {
      if (isOriginSelected(origin)) {
        selected.push(origin);
      }
    });
    ArchetypeOrigin.forEach((origin) => {
      if (isOriginSelected(origin)) {
        selected.push(origin);
      }
    });
    OtherOrigin.forEach((origin) => {
      if (isOriginSelected(origin)) {
        selected.push(origin);
      }
    });
    console.log(selected);
    onOriginsSelected(selected);
  };

  const toggleOriginSelection = (origin) => {
    let tempIds = SelectedOriginIds;
    if (isOriginSelected(origin)) {
      console.log(origin.pureOrigin);
      console.log(remainingPureOrigin());
      if (origin.pureOrigin === undefined || origin.pureOrigin <= remainingPureOrigin()) {
        tempIds = tempIds.filter((orId) => orId !== origin.id);
      }
    } else {
      tempIds.push(origin.id);
    }
    setSelectedOriginIds(tempIds);
    setforceRender({});
  };

  useEffect(() => {
    loadOrigins();
  }, []);

  return (
    <>
      <Helmet>
        <title>Phillogzs - Origin Selector</title>
      </Helmet>
      <Box sx={{ mt: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => returnSelectedOrigins()}
          >
            Sauver
          </Button>
        </Box>
      </Box>
      <Box {...forceRender}>
        <Box xs={12}>
          <Typography gutterBottom variant="h5" component="div" align="center">
            {`Origine Pure ${remainingPureOrigin()}`}
          </Typography>
        </Box>
        {isRequeting ? (
          <Box>
            <Card align="center">
              <CircularProgress />
            </Card>
          </Box>
        ) : (
          <Box 
            sx={{
              display: 'flex'
            }}
            maxHeight="100%"
          >
            <Box
              maxWidth="30%"
              maxHeight="100%"
            >
              <Typography gutterBottom variant="h5" component="div" align="center">
                {`Origine Neutre ${Math.max(remainingNeutralOrigin(), 0)} / ${totalNeutralOrigin}`}
              </Typography>
              <Box
                sx={{
                  overflowY: 'scroll'
                }}
              >
                {NeutralOrigin.map((origin) => (
                  <OriginCard 
                    xs={6}
                    origin={origin}
                    selected={isOriginSelected(origin)}
                    enabled={Math.max(remainingNeutralOrigin(), 0) + remainingPureOrigin() >= origin.cost}
                    onSelect={() => toggleOriginSelection(origin)}
                  />
                ))}
              </Box>
            </Box>
            <Box maxWidth="30%" maxHeight="100%">
              <Typography gutterBottom variant="h5" component="div" align="center">
                {`Origine Archetype ${Math.max(remainingArchetypeOrigin(), 0)} / ${totalArchetypeOrigin}`}
              </Typography>
              <Box
                sx={{
                  overflowY: 'scroll'
                }}
              >
                {ArchetypeOrigin.map((origin) => (
                  <OriginCard 
                    xs={6}
                    origin={origin}
                    selected={isOriginSelected(origin)}
                    enabled={Math.max(remainingArchetypeOrigin(), 0) + remainingPureOrigin() >= origin.cost}
                    onSelect={() => toggleOriginSelection(origin)}
                  />
                ))}
              </Box>
            </Box>
            <Box maxWidth="30%" maxHeight="100%">
              <Typography gutterBottom variant="h5" component="div" align="center">
                {`Origine Elementale ${Math.max(remainingOtherOrigin(), 0)} / ${totalOtherOrigin}`}
              </Typography>
              <Box
                sx={{
                  overflowY: 'scroll'
                }}
              >
                {OtherOrigin.map((origin) => (
                  <OriginCard 
                    xs={6}
                    origin={origin}
                    selected={isOriginSelected(origin)}
                    enabled={Math.max(remainingOtherOrigin(), 0) + remainingPureOrigin() >= origin.cost}
                    onSelect={() => toggleOriginSelection(origin)}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
    
  );
};

OriginSelector.propTypes = {
  character: PropTypes.object.isRequired,
  onOriginsSelected: PropTypes.func
};

export default OriginSelector;
