import PropTypes from 'prop-types';
// import moment from 'moment';
import {
  Button,
  TableCell,
  TableRow
} from '@mui/material';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

const CharacterListTableRow = (props) => {
  const { character, onDelete, onEdit, onDetail } = props;
  
  // const [open, setOpen] = useState(false);

  return (
    <TableRow hover key={character.id}>
      <TableCell>{character.lastName}</TableCell>
      <TableCell>{character.firstName}</TableCell>
      <TableCell>{character.owner.name}</TableCell>
      <TableCell>
        <Button
          startIcon={<VisibilityIcon />}
          color="primary"
          onClick={() => onDetail(character)}
        >
          Voir
        </Button>
        <Button
          startIcon={<EditIcon />}
          href="#text-buttons"
          color="primary"
          onClick={() => onEdit(character)}
        >
          Éditer
        </Button>
        <Button
          startIcon={<DeleteOutlineIcon />}
          color="secondary"
          onClick={() => onDelete(character)}
        >
          Supprimer
        </Button>
      </TableCell>
    </TableRow>
  );
};

CharacterListTableRow.propTypes = {
  character: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDetail: PropTypes.func.isRequired
};

export default CharacterListTableRow;
