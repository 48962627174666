import { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';

import 'react-perfect-scrollbar/dist/css/styles.css';

import { ThemeProvider, Snackbar } from '@mui/material';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import LoadConfig from './services/load-config.service';
import appConfig from './assets/config.json';
import AppContext from './contexts/AppContext';

const App = () => {
  const routing = useRoutes(routes);
  const [configLoaded, setconfigLoaded] = useState(false);

  const [message, setMessage] = useState({});

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage({});
  };

  useEffect(() => {
    LoadConfig()
      .then((res) => {
        window.API_URL = res.data.baseUrl;
        window.API_KEY = res.data.apiKey;
      })
      .catch(() => {
        window.API_URL = appConfig.baseUrl;
        window.API_KEY = appConfig.apiKey;
      })
      .finally(() => {
        console.log(window.API_URL);
        console.log(window.API_KEY);
        setconfigLoaded(true);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppContext.Provider value={{ setMessage }}>
        <Snackbar 
          ContentProps={{
            sx: {
              background: message.type === 'error' ? 'red' : 'green'
            }
          }}
          open={message.text} 
          autoHideDuration={4000} 
          onClose={handleMessageClose} 
          message={message.text}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        />
        {configLoaded && routing}
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default App;
