import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  CardHeader,
  Container,
  Dialog,
  Grid
} from '@mui/material';
import CharacterListBody from '../components/characters/CharacterListBody';
import CharacterForm from '../components/characters/CharacterForm';
import ConfirmDelete from '../components/characters/ConfirmDelete';
import { isAutenticated } from '../services/login.service';
import { deleteCharacter, getCharacterByPaginate } from '../services/character.service';

const CharactersPage = () => {
  const navigate = useNavigate();
  const [characters, setcharacters] = useState([]);
  const [open, setOpen] = useState(false);
  const [openConfirmationDialog, setopenConfirmationDialog] = useState(false);
  const [SelectedCharacter, setSelectedCharacter] = useState();
  const [count, setcount] = useState(0);
  const [isRequeting, setisRequeting] = useState(false);
  const [modalStyle] = useState({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  });

  const loadCharacters = async (page, limit) => {
    setisRequeting(true);

    try {
      const charactersResponse = await getCharacterByPaginate(page, limit);

      const { data: characterData } = charactersResponse;
      setcount(characterData.pageMax);
      setcharacters(characterData.data);

      setisRequeting(false);
    } catch (error) {
      setisRequeting(false);
    }
  };

  useEffect(() => {
    if (isAutenticated()) {
      loadCharacters(0, 10);
    } else {
      navigate('/login');
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const closeDialog = () => {
    setSelectedCharacter(null);
    setOpen(false);
    setopenConfirmationDialog(false);
  };

  /**
   * Delete character
   * @param {*} characterData 
   */
  const onDelete = async (characterToDelete) => {
    setSelectedCharacter(characterToDelete);
    setOpen(true);
    setopenConfirmationDialog(true);

    // await deleteCharacter(character.id);
    // loadCharacters(0, 10);
  };

  /**
   * Edit character
   * @param {*} characterData 
   */
  const onEdit = async (characterToEdit) => {
    console.log(characterToEdit);
    setSelectedCharacter(characterToEdit);
    setOpen(true);
  };

  /**
   * Edit character
   * @param {*} characterData 
   */
  const onDetail = async (characterToShow) => {
    console.log(characterToShow);
    navigate(`/app/characters/${characterToShow.id}`);
  };

  return (
    <>
      <Helmet>
        <title>Phillogzs - Utilisateurs</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <Card>
                <CardHeader title="Personnages" subheader="Liste des personnages" />
              </Card>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            />
            <Dialog open={open} onClose={handleClose} style={modalStyle}>
              {openConfirmationDialog ? (
                <ConfirmDelete
                  onClose={(isDelete) => {
                    if (isDelete) {
                      deleteCharacter(SelectedCharacter.id).then(() => {
                        closeDialog();
                        loadCharacters(0, 10);
                      });
                    } else {
                      closeDialog();
                    }
                  }}
                />
              ) : (
                <CharacterForm
                  character={SelectedCharacter}
                  onClose={(isRefresh) => {
                    closeDialog();
                    if (isRefresh) {
                      loadCharacters(0, 10);
                    }
                  }}
                />
              )}
            </Dialog>
            <Grid item lg={12}>
              <CharacterListBody
                data={characters}
                count={count}
                onPaginate={(page, limit) => loadCharacters(page, limit)}
                isRequeting={isRequeting}
                onDelete={onDelete}
                onEdit={onEdit}
                onDetail={onDetail}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default CharactersPage;
