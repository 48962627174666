import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import {
  createUser,
  updatePassword,
  updateUser
} from 'src/services/user.service';

const UserForm = ({ user, onClose }) => {
  const getPasswordValidator = () => {
    return !user
      ? Yup.string().max(255).required('Mot de passe est obligatoire')
      : null;
  };
  const getConfirmPasswordValidator = () => {
    if (user) {
      return Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Le mot de passe de confirmation que vous avez entré ne correspond pas'
      );
    }
    return Yup.string()
      .required()
      .oneOf(
        [Yup.ref('password'), null],
        'Le mot de passe de confirmation que vous avez entré ne correspond pas'
      );
  };
  return (
    <Formik
      initialValues={{
        firstname: user ? user.firstname : '',
        lastname: user ? user.lastname : '',
        nickname: user ? user.nickname : '',
        email: user ? user.email : '',
        password: '',
        confirmPassword: ''
      }}
      validationSchema={Yup.object().shape({
        firstname: Yup.string(),
        lastname: Yup.string(),
        nickname: Yup.string().required('Username est obligatoire'),
        email: Yup.string()
          .email('e-mail invalide')
          .max(255)
          .required('Email est obligatoire'),
        password: getPasswordValidator(),
        confirmPassword: getConfirmPasswordValidator()
      })}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        const { firstname, lastname, nickname, email, password, confirmPassword } = values;
        if (password && password !== confirmPassword) {
          setFieldError(
            'confirmPassword',
            'Le mot de passe de confirmation que vous avez entré ne correspond pas'
          );
          return;
        }
        if (user) {
          const urls = [updateUser(user.id, { firstname, lastname, nickname, email })];
          if (password) {
            urls.push(updatePassword(user.id, password));
          }
          Promise.all(urls).then(() => {
            setSubmitting(false);
            onClose(true);
          });
        } else {
          createUser({ firstname, lastname, nickname, email, password }).then(() => {
            setSubmitting(false);
            onClose(true);
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form
          autoComplete="off"
          noValidate
          style={{
            width: '500px'
          }}
        >
          <Card>
            <CardHeader
              subheader={
                user
                  ? "Modification d'un utilisateur"
                  : 'créer nouvel utilisateur'
              }
              title="Utilisateur"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.firstname && errors.firstname)}
                    fullWidth
                    helperText={touched.firstname && errors.firstname}
                    label="Firstname"
                    margin="normal"
                    name="firstname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.firstname}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    helperText={touched.lastname && errors.lastname}
                    label="Lastname"
                    margin="normal"
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.lastname}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.nickname && errors.nickname)}
                    fullWidth
                    helperText={touched.nickname && errors.nickname}
                    label="Username"
                    margin="normal"
                    name="nickname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.nickname}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Mot de passe"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    fullWidth
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    label="Confirmation Mot de passe "
                    margin="normal"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.confirmPassword}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                {user ? 'Modifier' : 'Ajouter'}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

UserForm.propTypes = {
  user: PropTypes.object,
  onClose: PropTypes.func
};

export default UserForm;
