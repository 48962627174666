import PropTypes from 'prop-types';
import {
  CircularProgress, CardActionArea
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import {
  getArchetypes
} from 'src/services/archetype.service';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const ArchetypeSelector = ({ onArchetypeSelected }) => {
  const [archetypes, setarchetypes] = useState([]);
  // const [selectedArchetype, setselectedArchetype] = useState();
  const [isRequeting, setisRequeting] = useState(false);

  const loadArchetypes = async () => {
    setisRequeting(true);

    try {
      const archetypesResponse = await getArchetypes();

      const { data: archetypesData } = archetypesResponse;
      setarchetypes(archetypesData);
      setisRequeting(false);
    } catch (error) {
      setisRequeting(false);
    }
  };

  useEffect(() => {
    loadArchetypes();
  }, []);

  return (
    <>
      <Helmet>
        <title>Phillogzs - ArchetypeSelector</title>
      </Helmet>
      <Grid container spacing={2}>
        {isRequeting ? (
          <Grid item xs={6}>
            <Card align="center">
              <CircularProgress />
            </Card>
          </Grid>
        ) : (
          archetypes.map((archetype) => (
            <Grid item xs={6} key={archetype.id}>
              <Card
                sx={{ 
                  backgroundColor: archetype.enabled ? '#FFFFFF' : '#CACACA',
                  margin: '5px'
                }}
              >
                <CardActionArea sx={{ minHeight: 400 }} onClick={() => onArchetypeSelected(archetype)} disabled={!archetype.enabled}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" align="center">
                      {archetype.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                      {archetype.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </>
    
  );
};

ArchetypeSelector.propTypes = {
  onArchetypeSelected: PropTypes.func
};

export default ArchetypeSelector;
