import { Post } from './network.service';

export const LoginRequest = (loginForm) => {
  return Post('login', loginForm);
};

export const isAutenticated = () => {
  return localStorage.getItem('access_token') !== null;
};

export const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

export const setToken = () => {
  localStorage.setItem();
};

export const logout = () => {
  localStorage.clear();
};
