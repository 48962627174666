import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  CircularProgress,
  Container,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import ArchetypeSelector from 'src/components/characters/ArchetypeSelector';
import ElementSelector from 'src/components/characters/ElementSelector';
import OriginSelector from 'src/components/characters/OriginSelector';
import { updateCharacter } from 'src/services/character.service';
import CharacterForm from '../components/characters/CharacterForm';
import { isAutenticated } from '../services/login.service';

const CharacterCreatorPage = () => {
  const navigate = useNavigate();
  const [isRequeting, setisRequeting] = useState(false);
  const [SelectedCharacter, setSelectedCharacter] = useState();
  const [SelectedArchetype, setSelectedArchetype] = useState();
  const [SelectedElement, setSelectedElement] = useState();

  useEffect(() => {
    if (isAutenticated()) {
      console.log('auth ok');
    } else {
      navigate('/login');
    }
  }, []);

  const updadeSelectedArchetype = async (archetype) => {
    setisRequeting(true);
    SelectedCharacter.archetype = archetype.id;
    try {
      const characterResponse = await updateCharacter(SelectedCharacter.id, SelectedCharacter);

      const { data: characterData } = characterResponse;
      console.log(characterData);
      setSelectedArchetype(archetype);
      setisRequeting(false);
    } catch (error) {
      setisRequeting(false);
    }
  };

  const updadeSelectedElement = async (element) => {
    setisRequeting(true);
    SelectedCharacter.element = element.id;
    try {
      const characterResponse = await updateCharacter(SelectedCharacter.id, SelectedCharacter);
      const { data: characterData } = characterResponse;
      console.log(characterData);
      setSelectedElement(element);
      setisRequeting(false);
    } catch (error) {
      setisRequeting(false);
    }
  };

  const updadeSelectedOrigins = async (origins) => {
    setisRequeting(true);
    SelectedCharacter.origins = origins;
    try {
      const characterResponse = await updateCharacter(SelectedCharacter.id, SelectedCharacter);
      const { data: characterData } = characterResponse;
      console.log(characterData);
      navigate(`/app/characters/${characterData.id}`);
    } catch (error) {
      setisRequeting(false);
    }
    console.log('updadeSelectedOrigins', origins);
    setisRequeting(false);
  };

  return (
    <>
      <Helmet>
        <title>Phillogzs - Création de personnage</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          {isRequeting && (
            <Grid container spacing={2}>
              <Grid item xs={6} align="center">
                <CircularProgress />
              </Grid>
            </Grid>
          )}
          {!isRequeting && !SelectedCharacter && (
            <CharacterForm
              character={SelectedCharacter}
              onClose={(_, data) => {
                setSelectedCharacter(data);
              }}
            />
          )}
          {!isRequeting && SelectedCharacter && !SelectedArchetype && (
            <ArchetypeSelector
              onArchetypeSelected={(archetype) => {
                updadeSelectedArchetype(archetype);
              }}
            />
          )}
          {!isRequeting && SelectedCharacter && SelectedArchetype && !SelectedElement && (
            <ElementSelector
              onElementSelected={(element) => {
                updadeSelectedElement(element);
              }}
            />
          )}
          {!isRequeting && SelectedCharacter && SelectedArchetype && SelectedElement && (
            <OriginSelector
              character={SelectedCharacter}
              onOriginsSelected={(origins) => {
                updadeSelectedOrigins(origins);
              }}
            />
          )}
          
        </Container>
      </Box>
    </>
  );
};
export default CharacterCreatorPage;
