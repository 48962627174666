import PropTypes from 'prop-types';
import {
  CardActionArea
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const OriginCard = (props) => {
  const { origin, selected, enabled, onSelect } = props;

  const handleSelect = () => {
    if (enabled || selected) {
      onSelect(origin);
    }
  };

  // const [open, setOpen] = useState(false);

  return (
    <Card 
      key={origin.id}
      sx={{ 
        backgroundColor: enabled || selected ? '#FFFFFF' : '#CACACA',
        border: selected ? '2px solid green' : '2px solid white',
        margin: '5px'
      }}
    >
      <CardActionArea sx={{ minHeight: 400 }} onClick={() => handleSelect()} disabled={!enabled && !selected}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" align="center">
            {origin.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            {origin.description}
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            Count : 
            {origin.cost}
            ON
          </Typography>
          {origin.pureOrigin > 0 ? (
            <Typography variant="body2" color="text.secondary" align="center">
              Gain : 
              {origin.pureOrigin}
              OP
            </Typography>
          ) : (
            null
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

OriginCard.propTypes = {
  origin: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
  enabled: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default OriginCard;
