import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import {
  createCharacter,
  updateCharacter
} from 'src/services/character.service';

const CharacterForm = ({ character, onClose }) => {
  return (
    <Formik
      initialValues={{
        lastName: character ? character.lastName : '',
        firstName: character ? character.firstName : '',
        age: character ? character.age : '',
        size: character ? character.size : '',
        weight: character ? character.weight : '',
        sex: character ? character.sex : '',
        hair: character ? character.hair : '',
        eyes: character ? character.eyes : '',
        luckyNumber: character ? character.luckyNumber : '',
        other: character ? character.other : '',
      }}
      validationSchema={Yup.object().shape({
        lastName: Yup.string().required('le nom est obligatoire'),
        firstName: Yup.string().required('le prénom est obligatoire'),
        age: Yup.number(),
        size: Yup.string(),
        weight: Yup.string(),
        sex: Yup.string(),
        hair: Yup.string(),
        eyes: Yup.string(),
        luckyNumber: Yup.number().min(2, 'le nombre doi être supérieur à 1').max(99, 'Le nombre doit être inférieur à 100').required('le numéro chance est obligatoire'),
        other: Yup.string(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const { lastName, firstName, age, size, weight, sex, hair, eyes, luckyNumber, other } = values;
        if (character) {
          const urls = [updateCharacter(character.id, { lastName, firstName, age, size, weight, sex, hair, eyes, luckyNumber, other })];
          Promise.all(urls).then(() => {
            setSubmitting(false);
            onClose(true);
          });
        } else {
          createCharacter({ lastName, firstName, age, size, weight, sex, hair, eyes, luckyNumber, other }).then((response) => {
            setSubmitting(false);
            onClose(true, response.data);
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form
          autoComplete="off"
          noValidate
          style={{
            width: '500px'
          }}
        >
          <Card>
            <CardHeader
              subheader={
                character
                  ? "Modification d'un personnage"
                  : 'créer nouveau personnage'
              }
              title="Utilisateur"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    label="Nom"
                    margin="normal"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.lastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    label="Prénom"
                    margin="normal"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.age && errors.age)}
                    fullWidth
                    helperText={touched.age && errors.age}
                    label="Age"
                    margin="normal"
                    name="age"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.age}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.size && errors.size)}
                    fullWidth
                    helperText={touched.size && errors.size}
                    label="Taille"
                    margin="normal"
                    name="size"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.size}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.weight && errors.weight)}
                    fullWidth
                    helperText={touched.weight && errors.weight}
                    label="Poids"
                    margin="normal"
                    name="weight"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.weight}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.sex && errors.sex)}
                    fullWidth
                    helperText={touched.sex && errors.sex}
                    label="Sexe"
                    margin="normal"
                    name="sex"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.sex}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.hair && errors.hair)}
                    fullWidth
                    helperText={touched.hair && errors.hair}
                    label="Cheveux"
                    margin="normal"
                    name="hair"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.hair}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.eyes && errors.eyes)}
                    fullWidth
                    helperText={touched.eyes && errors.eyes}
                    label="Yeux"
                    margin="normal"
                    name="eyes"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.eyes}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.luckyNumber && errors.luckyNumber)}
                    fullWidth
                    helperText={touched.luckyNumber && errors.luckyNumber}
                    label="Numéro Chance"
                    margin="normal"
                    name="luckyNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.luckyNumber}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.other && errors.other)}
                    fullWidth
                    helperText={touched.other && errors.other}
                    label="Autre"
                    margin="normal"
                    name="other"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.other}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                {character ? 'Modifier' : 'Continuer'}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

CharacterForm.propTypes = {
  character: PropTypes.object,
  onClose: PropTypes.func
};

export default CharacterForm;
