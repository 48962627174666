import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import jwtdecode from 'jwt-decode';
// import moment from 'moment';
import {
  Button,
  TableCell,
  TableRow
} from '@mui/material';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { getAccessToken, isAutenticated } from 'src/services/login.service';

const UserListTableRow = (props) => {
  const { user, onDelete, onEdit } = props;
  const [userId, setuserId] = useState();
  useEffect(() => {
    if (isAutenticated()) {
      const { id } = jwtdecode(getAccessToken());
      setuserId(id);
    }
  }, []);

  // const [open, setOpen] = useState(false);

  return (
    <TableRow hover key={user.id}>
      <TableCell>{user.firstname}</TableCell>
      <TableCell>{user.lastname}</TableCell>
      <TableCell>{user.nickname}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>
        <Button
          startIcon={<EditIcon />}
          href="#text-buttons"
          color="primary"
          onClick={() => onEdit(user)}
        >
          Éditer
        </Button>
        {userId !== user.id && (
          <Button
            startIcon={<DeleteOutlineIcon />}
            color="secondary"
            onClick={() => onDelete(user)}
          >
            Supprimer
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

UserListTableRow.propTypes = {
  user: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default UserListTableRow;
