import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress
} from '@mui/material';
import UserListTableRow from './UserListTableRow';

const UserListBody = (props) => {
  const { data, onPaginate, count, isRequeting, onDelete, onEdit } = props;
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    onPaginate(page + 1, event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    onPaginate(newPage + 1, limit);
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Firstname</TableCell>
                <TableCell>Lastname</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell style={{ width: '250px' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isRequeting ? (
                <TableRow hover>
                  <TableCell colSpan={11} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                data.map((user) => (
                  <UserListTableRow key={`user-${user.id}`} user={user} onDelete={onDelete} onEdit={onEdit} cakey={[user.id, '-table-row'].join()} />
                ))
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count * limit}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        showFirstButton
        showLastButton
      />
    </Card>
  );
};

UserListBody.propTypes = {
  data: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  onPaginate: PropTypes.func.isRequired,
  isRequeting: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default UserListBody;
