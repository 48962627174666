import { Delete, Get, Post, Put } from './network.service';

export const getUsers = () => {
  return Get('users');
};

export const getUserByPaginate = (page, size) => {
  return Get(`users?page=${page}&take=${size}`);
};

export const createUser = (body) => {
  return Post('users', body);
};

export const updateUser = (userId, body) => {
  return Put(`users/${userId}`, body);
};

export const updatePassword = (userId, password) => {
  return Put(`users/${userId}/password`, { password });
};

export const deleteUser = (userId) => {
  return Delete(`users/${userId}`);
};
