import PropTypes from 'prop-types';
import {
  CircularProgress, CardActionArea
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import {
  getElements
} from 'src/services/element.service';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const ElementSelector = ({ onElementSelected }) => {
  const [elements, setelements] = useState([]);
  // const [selectedElement, setselectedElement] = useState();
  const [isRequeting, setisRequeting] = useState(false);

  const loadElements = async () => {
    setisRequeting(true);

    try {
      const elementsResponse = await getElements();

      const { data: elementsData } = elementsResponse;
      setelements(elementsData);
      setisRequeting(false);
    } catch (error) {
      setisRequeting(false);
    }
  };

  useEffect(() => {
    loadElements();
  }, []);

  return (
    <>
      <Helmet>
        <title>Phillogzs - ElementSelector</title>
      </Helmet>
      <Grid container spacing={2}>
        {isRequeting ? (
          <Grid item xs={6}>
            <Card align="center">
              <CircularProgress />
            </Card>
          </Grid>
        ) : (
          elements.map((element) => (
            <Grid item xs={6} key={element.id}>
              <Card
                sx={{ 
                  backgroundColor: element.enabled ? '#FFFFFF' : '#CACACA',
                  margin: '5px'
                }}
              >
                <CardActionArea sx={{ minHeight: 400 }} onClick={() => onElementSelected(element)} disabled={!element.enabled}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" align="center">
                      {element.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                      {element.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </>
    
  );
};

ElementSelector.propTypes = {
  onElementSelected: PropTypes.func
};

export default ElementSelector;
